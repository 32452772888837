import React from 'react';
import {
  Container, Row, Col, Tabs, Tab,
} from 'react-bootstrap';
import { ServicesOffered } from '../../../data/solutions-db';

export default function SolutionTab() {
  return (
    <section className="solutions-tab-body py-5">
      <Container>
        <Row>
          <h2 className="text-center pb-4">
            {ServicesOffered[0].heading}
            {' '}
            <span className="infenox-text-highlight size-line" />
          </h2>
          <Tabs defaultActiveKey="care" className="solutions-tab-bg">
            {ServicesOffered[0].data.map(({
              id, heading, key, text, image,
            }) => (
              <Tab eventKey={key} title={heading} key={id}>
                <Row>
                  <h4 className="solutions-tab-heading py-5">{heading}</h4>
                  <Col className="col-12 col-md-6"><img src={image} alt={heading} className="solutions-tab-image" /></Col>
                  <Col className="col-12 col-md-6 fs-6 custom-text-justify"><p>{text}</p></Col>
                </Row>
              </Tab>
            ))}
          </Tabs>
        </Row>
      </Container>
    </section>

  );
}
